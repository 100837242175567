<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div
        class="content-wrapper container-xxl p-0 font"
        v-if="loadPermissions('admin-view') == false"
      >
        <div class="p-2">
          <img style="width: 100%" src="/assets/image/accessDenide.png" alt="" />
        </div>
      </div>
      <div class="content-wrapper container-xxl p-0 font" v-else>
        <banner
          title="ADMIN PROFILE"
          :breadcrumb="[
            {
              label: 'Super Admin',
            },
            { label: 'Admin Profile' },
          ]"
        >
        </banner>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-5 col-md-5 col-12" style="padding:5px 5px 5px 5px">
            <div
              class="card custom-card"
              style="
                height: 100%;
            
              "
            >
              <div class="card-body pt-2">
                <div class="row">
                  <div class="col">
                    <h4 class="d-xl-none d-md-none d-block text-center">
                      <b style="color: #00364f; font-weight: bolder">Admin Profile</b>
                    </h4>
                    <h4 class="d-none d-xl-block d-md-block text-start">
                      <b style="color: #00364f; font-weight: bolder">Admin Profile</b>
                    </h4>
                  </div>
                </div>

                <div class="row">
                  <!-- img responsive mobile  -->
                  <div
                    class="col-sm-12 col-12 d-block d-md-none d-xl-none d-lg-none text-center mb-1"
                  >
                    <img
                      style="width: 40%; border-radius: 15px"
                      :src="profileImg"
                      alt=""
                    />
                    <br />
                    <span style="color: #00364f">
                      <p
                        v-if="admin.loginStatus == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                  <h5 class="text-start">
                    <b style="color: #f21300; font-weight: bolder">Personal Details </b>
                  </h5>

                  <div class="col-xl-9 col-md-9 col-lg-9 col-sm-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Code : </b>
                      <span v-if="admin">{{ admin.code }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Name : </b>
                      <span v-if="admin">{{ admin.name }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>DOB : </b>
                      <span v-if="admin">{{ dateTime(admin.dob) }} </span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Gender : </b>
                      <span v-if="admin">{{ admin.gender }}</span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Phone Number : </b>
                      <span v-if="admin">+91-{{ admin.phone }}</span>
                    </div>
                    
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Alt Phone Number : </b>
                      <span v-if="admin">+91-{{ admin.alternativePhone }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Aadhaar Number : </b>
                      <span v-if="admin.admin_kyc">{{ admin.admin_kyc.aadharNo }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Pan : </b>
                      <span v-if="admin.admin_kyc">{{ admin.admin_kyc.panNo }}</span>
                    </div>
                  </div>
                  <!-- img responsive Pc  -->
                  <div
                    class="col-xl-3 col-md-3 col-lg-3 d-none d-xl-block d-md-block d-lg-block text-center"
                  >
                    <img
                      style="width: 100%; border-radius: 15px; margin-top: -20px"
                      :src="profileImg"
                      alt=""
                    />
                    <span style="color: #00364f; width: 40%">
                      <p
                        v-if="admin.loginStatus == 'true'"
                        class="text-white btn btn-sm"
                        style="
                          background-color: green;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> Active</b>
                      </p>
                      <p
                        v-else
                        class="text-white btn btn-sm"
                        style="
                          background-color: red;
                          width: 70px;
                          box-shadow: 2px 2px 5px gray;
                          border-radius: 50px;
                          font-size: 10px;
                          padding-top: 5px;
                          padding-bottom: 5px;
                          padding-left: 7px;
                          padding-right: 7px;
                          margin-top: 5px;
                          margin-bottom: 5px;
                        "
                      >
                        <b> InActive</b>
                      </p>
                    </span>
                  </div>
                </div>
                <div class="col-12" style="margin-left: 7px">
                  <div class="text">
                    <img
                      style="width: 8px; margin-right: 5px"
                      src="/assets/image/record.png"
                      alt=""
                    />
                    <b>Address : </b>
                    <span v-if="admin"
                      >{{ admin.address }} {{ admin.city }} {{ admin.pin }}</span
                    >
                  </div>
                </div>
                <!-- bussiness Detais  -->
                <div class="row">
                  <h5 class="text-start mt-2">
                    <b style="color: #f21300; font-weight: bolder">Business Details </b>
                  </h5>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Firm Name: </b>
                      <span>{{ admin.orgnization }} </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Business Address : </b>
                      <span
                        >{{ admin.businessAddress }} {{ admin.businessCity }}
                        {{ admin.businessPin }}
                      </span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Business Pan : </b>
                      <span v-if="admin">{{ admin.businessPan }} </span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Official Email Id : </b>
                      <span v-if="admin">{{ admin.email }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>DOI : </b>
                      <span v-if="admin">{{ dateTime(admin.doi) }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>State : </b>
                      <span v-if="admin.state"
                        >{{ admin.state.name }} ,{{ admin.state.shortName }}
                      </span>
                    </div>

                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>GSTIN : </b>
                      <span v-if="admin">{{ admin.gstin }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>TDS : </b>
                      <span v-if="admin">{{ admin.tds }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>CIN : </b>
                      <span v-if="admin">{{ admin.cin }}</span>
                    </div>
                  </div>
                </div>
                <!-- end  bussiness Detais  -->

                <!-- Qualifications -->
                <!-- <div class="row">
                  <h5 class="text-start mt-2">
                    <b style="color: #f21300; font-weight: bolder">Account Details </b>
                  </h5>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Account Number: </b>
                      <span>-------</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Account Type: </b>
                      <span>-------</span>>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Status: </b>
                      <span>-------</span>
                    </div>
                  </div>
                </div> -->

                <!-- end Qualifications -->

                <!-- Specialisation -->
                <div class="row">
                  <h5 class="text-start mt-2">
                    <b style="color: #f21300; font-weight: bolder"
                      >Credentials Details
                    </b>
                  </h5>
                  <div class="col-xl-12 col-lg-12 col-md-12 col-12">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Portal : </b>
                      <span v-if="admin.portal == 'true'">Platform Module</span>

                      <span v-if="admin.portal == 'false'">Franchise Module</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Secret Key : </b>
                      <span v-if="admin">{{ admin.secretKey }}</span>
                    </div>
                    <div class="text" v-if="admin.portal == 'true'">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Call Back Url : </b>
                      <span v-if="admin">{{ admin.callbackUrl }}</span>
                    </div>
                    <div class="text" v-if="admin.portal == 'true'">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Success Url : </b>
                      <span v-if="admin">{{ admin.successUrl }}</span>
                    </div>
                    <div class="text" v-if="admin.portal == 'true'">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Failed Url : </b>
                      <span v-if="admin">{{ admin.failedUrl }}</span>
                    </div>
                    <div class="text" v-if="admin.portal == 'true'">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>End Point : </b>
                      <span v-if="admin">{{ admin.endpoint }}</span>
                    </div>
                    <div class="text" v-if="admin.portal == 'true'">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Method : </b>
                      <span v-if="admin">{{ admin.method }}</span>
                    </div>
                    <div class="text" v-if="admin.portal == 'true'">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Parameter : </b>
                      <span v-if="admin">{{ admin.parameter }}</span>
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-12 text-end">
                    <!-- <router-link
                      :to="`/super-admin/update-admins/${this.$route.params.id}`"
                    >
                      <button type="button" class="btn btns text-white btn-sm">
                        <b
                          >Edit Profile &nbsp;
                          <font-awesome-icon icon="edit" />
                        </b>
                      </button>
                    </router-link> -->
                    <button
                      v-if="loadPermissions('admin-kyc-view') != false"
                      type="button"
                      class="btn text-white btn-sm"
                      data-bs-toggle="modal"
                      data-bs-target="#doccuments"
                      style="background-color: #f21300; margin-right: 5px"
                    >
                      <b
                        >Kyc Doccuments &nbsp;
                        <i class="fa fa-hand-pointer-o"></i>
                      </b>
                    </button>
                  </div>
                </div>
                <div
                  class="row mt-1"
                  style="background-color: #e3e3e3; border-radius: 15px"
                >
                  <div class="col-12 p-1">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>IP Address :</b>
                      <span v-if="admin">{{ admin.ipAddress }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Last Login :</b>
                      <span v-if="admin">{{ dateTimeWithTime(admin.last_login)}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-xl-7 col-md-7 col-12 d-none" style="padding:5px 5px 5px 5px">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div
              class="card custom-card"
              style="height: 100%"
            >
              <div class="card-body">
                <div class="row" v-if="!wallet.id">
                  <div class="col-xl-12">
                    <button
                      type="button"
                      class="btn btn-sm text-white"
                      data-bs-toggle="modal"
                      data-bs-target="#createwallet"
                      style="background-color: #f21300"
                    >
                      Create Wallet
                    </button>
                  </div>
                </div>
                <div class="row" v-else>
                  <div class="col-12">
                    <h4 class="d-xl-none d-md-none d-block text-center">
                      <b style="color: #00364f; font-weight: bolder">Wallet</b>
                    </h4>
                    <h4 class="d-none d-xl-block d-md-block text-start">
                      <b style="color: #00364f; font-weight: bolder">Wallet</b>
                    </h4>
                  </div>
                  <!-- Qr Code responsive Mobile screen  -->
                  <div
                    class="d-xl-none d-md-none d-lg-none d-block col-sm-12 col-12 text-center"
                  >
                    <!-- <img style="width:40%;" :src="wallet.qr_path" alt=""> -->
                    <img style="width: 40%" :src="wallet.qr_path" alt="" /><br />
                    <p
                      class="text-white btn btn-sm"
                      style="
                        background-color: green;
                        width: 32%;
                        box-shadow: 2px 2px 5px gray;
                        border-radius: 50px;
                        font-size: 10px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 7px;
                        padding-right: 7px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                        margin-left: 8px;
                      "
                    >
                      <b> Active</b>
                    </p>
                  </div>

                  <!-- end Qr Code responsive Mobile screen  -->
                  <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Name : </b>
                      <span v-if="wallet">{{ wallet.beneficiary_name }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Acount Number : </b>
                      <span v-if="wallet">{{ wallet.beneficiary_account_number }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>IFSC : </b>
                      <span v-if="wallet">{{ wallet.ifsc_code }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Wallet Ballance : </b>
                      <span v-if="wallet">{{ wallet.balanceAmount }}</span>
                    </div>
                    <div class="text">
                      <img
                        style="width: 8px; margin-right: 5px"
                        src="/assets/image/record.png"
                        alt=""
                      />
                      <b>Virtual Account : </b>
                      <span v-if="wallet">{{ wallet.merchant_vpa }}</span>
                    </div>
                  </div>

                  <!-- Qr Code responsive Pc screen  -->
                  <div
                    class="col-xl-2 col-lg-2 col-md-2 d-none d-xl-block d-lg-block d-md-block"
                  >
                    <!-- <img style="width:100%;" :src="wallet.qr_path" alt="qr code"> -->
                    <img style="width: 100%" :src="wallet.qr_path" alt="" />
                    <p
                      class="text-white btn btn-sm"
                      style="
                        background-color: green;
                        width: 70px;
                        box-shadow: 2px 2px 5px gray;
                        border-radius: 50px;
                        font-size: 10px;
                        padding-top: 5px;
                        padding-bottom: 5px;
                        padding-left: 7px;
                        padding-right: 7px;
                        margin-top: 5px;
                        margin-bottom: 5px;
                        margin-left: 8px;
                      "
                    >
                      <b> Active</b>
                    </p>
                  </div>

                  <!-- end Qr Code responsive Pc screen  -->
                </div>

                <!-- start list area  -->
                <div class="row mt-3" v-if="wallet.id">
                  <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-6 text-start">
                    <h4>
                      <b style="color: #00364f; font-weight: bolder"
                        >Beneficiary Account</b
                      >
                    </h4>
                  </div>
                  <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-6 text-end">
                    <h4>
                      <b style="color: #00364f; font-weight: bolder"
                        ><button
                          @click="modalHeading"
                          type="button"
                          class="btn text-white btn-sm"
                          style="
                            background-color: #f21300;
                            font-size: 13px;
                            letter-spacing: 1px;
                          "
                          data-bs-toggle="modal"
                          data-bs-target="#createbeneficiary"
                        >
                          <b> <font-awesome-icon icon="plus" /> Create </b>
                        </button></b
                      >
                    </h4>
                  </div>

                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <!-- <th></th> -->
                          <th><b>+</b></th>
                          <th>Name</th>

                          <th>Bank</th>
                          <th>Ifsc</th>
                          <th>Ac No.</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>

                        <tbody>
                          <tr v-for="(acount, index) in beneficiaryaccount" :key="index">
                            <td>
                              <img
                                style="width: 8px; margin-left: 8px"
                                src="/assets/image/record.png"
                                alt=""
                              />
                            </td>
                            <td>{{ acount.account_holder_name }}</td>
                            <td>{{ acount.bank_name }}</td>
                            <td>{{ acount.ifsc_code }}</td>
                            <td>{{ acount.account_number }}</td>

                            <td>
                              <p
                                v-if="acount.status == 'true'"
                                class="text-white btn btn-sm"
                                style="
                                  background-color: green;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> Active</b>
                              </p>
                              <p
                                v-else
                                class="text-white btn btn-sm"
                                style="
                                  background-color: red;
                                  width: 70px;
                                  box-shadow: 2px 2px 5px gray;
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding-top: 5px;
                                  padding-bottom: 5px;
                                  padding-left: 7px;
                                  padding-right: 7px;
                                  margin-top: 5px;
                                  margin-bottom: 5px;
                                "
                              >
                                <b> InActive</b>
                              </p>
                            </td>
                            <td class="text-end">
                              <div class="btn-group btn-group-sm" role="group">
                                <button
                                  type="button"
                                  data-bs-toggle="modal"
                                  data-bs-target="#createbeneficiary"
                                  @click="editBeneficiaryAcount(acount, 'Update')"
                                  class="btn btns btn-sm btn-success"
                                  style="padding: 5px 4.5px 5px 4.5px"
                                >
                                  <font-awesome-icon icon="edit" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr style="color: #e3e3e3" class="mt-2" />
                  <div class="row">
                    <div class="col-xl-6 col-md-6 col-lg-6 col-sm-6 col-6 text-start">
                      <h4>
                        <b style="color: #00364f; font-weight: bolder">Withdrawls</b>
                      </h4>
                    </div>
                  </div>
                  <hr style="color: #e3e3e3" />
                </div>
                <!-- end list area -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- start model  -->
  <div
    class="modal fade"
    id="createwallet"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content" >
        <div class="modal-header bg-white" >
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="color: #00364f; font-size: 1.4vw"
          >
            <b>New Wallet Account</b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form>
            <div class="row">
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-right: 5px">
                <div class="from-group">
                  <input type="text" class="form-control" placeholder="Account Holder" />
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-left: 5px">
                <div class="from-group">
                  <input
                    type="number"
                    class="form-control"
                    placeholder="Account Number"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-right: 5px">
                <div class="from-group">
                  <input type="text" class="form-control" placeholder="IFSC " />
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-left: 5px">
                <div class="from-group">
                  <input type="text" class="form-control" placeholder="Bank Name" />
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="from-group">
                  <input
                    type="number"
                    class="form-control"
                    placeholder=" Mobile Number"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="from-group">
                  <textarea class="form-control" rows="4" placeholder="Remark"></textarea>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                <b>Cancel</b>
              </button>
              <button
                type="submit"
                class="btn text-white"
                style="background-color: #00364f"
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->
  <!-- start model  -->
  <div
    class="modal fade"
    id="createbeneficiary"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    style="border-radius: 50px"
  >
    <div class="modal-dialog">
      <div class="modal-content" style="border-radius: 1.4vw">
        <div class="modal-header bg-white" style="border-radius: 1.4vw">
          <h5
            class="modal-title"
            id="exampleModalLabel"
            style="color: #00364f; font-size: 1.4vw"
          >
            <b id="modal-heading-update">New</b> <b> Beneficiary Account</b>
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form
            @submit.prevent="
              edit == 'false' ? createbeneficiaryAcount() : updatebeneficiaryAcount()
            "
          >
            <div class="row">
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-right: 5px">
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="text"
                    class="form-control"
                    v-model="beneficiary_Form.name"
                    placeholder="Account Holder"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="text"
                    class="form-control"
                    v-model="beneficiary_Form.name"
                    placeholder="Account Holder"
                  />
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-left: 5px">
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="number"
                    v-model="beneficiary_Form.account_number"
                    class="form-control"
                    placeholder="Account Number"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="number"
                    v-model="beneficiary_Form.account_number"
                    class="form-control"
                    placeholder="Account Number"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-right: 5px">
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="text"
                    v-model="beneficiary_Form.ifsc"
                    class="form-control"
                    placeholder="IFSC "
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="text"
                    v-model="beneficiary_Form.ifsc"
                    class="form-control"
                    placeholder="IFSC "
                  />
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-6 col-12" style="padding-left: 5px">
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="text"
                    v-model="beneficiary_Form.bank_name"
                    class="form-control"
                    placeholder="Bank Name"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="text"
                    v-model="beneficiary_Form.bank_name"
                    class="form-control"
                    placeholder="Bank Name"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="from-group" v-if="this.edit == 'true'">
                  <input
                    type="number"
                    v-model="beneficiary_Form.phone"
                    class="form-control"
                    placeholder=" Mobile Number"
                    disabled
                  />
                </div>
                <div class="from-group" v-else>
                  <input
                    type="number"
                    v-model="beneficiary_Form.phone"
                    class="form-control"
                    placeholder=" Mobile Number"
                  />
                </div>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-xl-12 col-md-12 col-sm-12 col-12">
                <div class="from-group">
                  <select
                    class="form-select"
                    v-model="beneficiary_Form.status"
                    aria-label="Default select example"
                    placeholder="Status"
                  >
                    <option value="true">Active</option>
                    <option value="false">InActive</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn text-white"
                style="background-color: #f21300"
                data-bs-dismiss="modal"
              >
                <b>Cancel</b>
              </button>
              <button
                type="submit"
                class="btn text-white"
                data-bs-dismiss="modal"
                style="background-color: #00364f"
              >
                <b>Confirm</b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <!-- end  modal -->

  <!-- Modal kyc doccuments show  -->
   <!-- Modal kyc doccuments show  -->
   <div
    class="modal fade"
    id="doccuments"
    tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel"><b>Kyc Doccuments</b></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-if="loadPermissions('admin-kyc-view') == false">
            <img style="width: 100%" src="/assets/image/accessDenide.png" alt="" />
          </div>

          <div class="container-fluid table-scroll" v-else>
            <table class="table table-hover table-sm text">
              <tr class="table-wrapper-scroll-y tr-head rounded-circle">
                <th>+</th>
                <th>Name</th>
                <th>Action</th>
              </tr>

              <tbody>
                <tr style="color: #00364f">
                  <td>
                    <img style="width: 30px; height: 30px" :src="adharFrontImg" alt="" />
                  </td>

                  <td><p>Aadhar Front</p></td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycAdharFront.toLowerCase() == 'pdf'
                            ? openWindow(adharFrontImgPdf)
                            : openWindow(adharFrontImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img style="width: 30px; height: 30px" :src="adharBackImg" alt="" />
                  </td>

                  <td>Aadhar Back</td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                          extensionkycAdharBack.toLowerCase() == 'pdf'
                            ? openWindow(adharBackImgPdf)
                            : openWindow(adharBackImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr style="color: #00364f">
                  <td>
                    <img style="width: 30px; height: 30px" :src="panImg" alt="" />
                  </td>

                  <td>
                    <p>Pan Card</p>
                  </td>
                  <td class="text-end">
                    <div class="btn-group btn-group-sm" role="group">
                      <button
                        @click="
                        extensionkycPanPhoto.toLowerCase() == 'pdf'
                            ? openWindow(panImgPdf)
                            : openWindow(panImg)
                        "
                        style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                        class="btn btns text-white btn-sm"
                      >
                        <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>



                <tr>
                <td>
                  <img
                    style="width: 40px; height: 40px"
                    v-if="coiPreImg"
                    :src="coiPreImg"
                  />
                </td>

                <td class="text">
                  <b for="coi-img">COI</b>
                </td>
                <td class="text-end">
                  <button
                    @click="
                      extensionCoi.toLowerCase() == 'pdf'
                        ? openWindow(coiPreImgPdf)
                        : openWindow(coiPreImg)
                    "
                    style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                    class="btn btns text-white btn-sm"
                  >
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>

              <tr>
                <td>
                  <img
                    style="width: 40px; height: 40px"
                    v-if="tdsPreImg"
                    :src="tdsPreImg"
                  />
                </td>

                <td class="text">
                  <b for="tds-photo">TAN</b>
                
                </td>
                <td class="text-end">
                  <button
                    @click="
                      extensionTds.toLowerCase() == 'pdf'
                        ? openWindow(tdsPreImgPdf)
                        : openWindow(tdsPreImg)
                    "
                    style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                    class="btn btns text-white btn-sm"
                  >
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>

              <tr>
                <td>
                  <img
                    style="width: 40px; height: 40px"
                    v-if="gstinPreImg"
                    :src="gstinPreImg"
                  />
                </td>

                <td class="text">
                  <b for="gstin-photo">GSTIN</b>
                 
                </td>
                <td class="text-end">
                  <button
                    @click="
                      extensionGstIn.toLowerCase() == 'pdf'
                        ? openWindow(GstInPreImgPdf)
                        : openWindow(gstinPreImg)
                    "
                    style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                    class="btn btns text-white btn-sm"
                  >
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>

              <tr>
                <td>
                  <img
                    style="width: 40px; height: 40px"
                    v-if="businessPanPreImg"
                    :src="businessPanPreImg"
                  />
                </td>

                <td class="text-truncate text">
                  <b for="businessPan-photo">Business Pan </b>
               
                </td>
                <td class="text-truncate text-end">
                  <button
                    @click="
                      extensionBusinessPan.toLowerCase() == 'pdf'
                        ? openWindow(businessPanPreImgPdf)
                        : openWindow(businessPanPreImg)
                    "
                    style="padding: 5px 4.5px 5px 4.5px; background-color: #00364f"
                    class="btn btns text-white btn-sm"
                  >
                    <i class="fa fa-download" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
            <!-- </form> -->
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn text-white btn-sm"
            data-bs-dismiss="modal"
            style="background-color: #f21300"
          >
            Close
          </button>
          <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

//
<script>
import Permissions from "../../../mixins/permission";
import moment from "moment";
import Banner from "../../../components/admin/comman/Banner.vue";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "AdminProfileStaff",
  mixins: [Permissions],
  components: {
    Banner,
  },
  data() {
    return {


      extensionkycAdharFront: "",
      extensionkycAdharBack: "",
      extensionkycPanPhoto: "",
      businessPanPreImgPdf: "",
      cinPreImgPdf: "",
      GstInPreImgPdf: "",
      coiPreImgPdf: "",
      tdsPreImgPdf: "",
      extensionCoi: "",
      extensionGstIn: "",
      extensionLogo: "",
      extensionTds: "",
      extensionBusinessPan: "",
      extensionBankStatement: "",
      extensionCin: "",
      businessPanPreImg: this.$store.state.placeholderImg,
      gstinPreImg: this.$store.state.placeholderImg,
      tdsPreImg: this.$store.state.placeholderImg,
      cinPreImg: this.$store.state.placeholderImg,
      coiPreImg: this.$store.state.placeholderImg,
      adharFrontImg: this.$store.state.placeholderImg,
      adharFrontImgPdf: "",
      adharBackImg: this.$store.state.placeholderImg,
      adharBackImgPdf: "",
      panImg: this.$store.state.placeholderImg,
      panImgPdf: "",


      
      extensionkycPan: "",
      aadharFrontPdf: "",
      aadharBackPdf: "",
      passBookPdf: "",
      edit: "false",

      profileImg: this.$store.state.placeholderImg,
      services: [],
      adminprofile: {},
      admin: {},
      state: {},
      wallet: {},
      beneficiaryaccount: [],
      beneficiary_Form_id: null,
      beneficiary_Form: {
        admin_id: null,
        beneficiary_id: null,
        account_number: null,
        name: "",
        ifsc: null,
        bank_name: "",
        phone: "",
        status: "",
        remark: "",
      },
      adminKycs: {},
    };
  },
  methods: {
   
    dateTime(value) {
      console.log(value);
       if(value){
  return moment(value).format("DD-MM-YYYY");
      }
    
    },
    dateTimeWithTime(value) {
      console.log(value);
      if(value){
return moment(value).format("DD-MM-YYYY,  HH:mm ");
      }
      
    },
    // adminKycsLoading() {
    //   this.$axios
    //     .get(`superadmin/adminkyc/${this.$route.params.id}`, {
    //       headers: { Authorization: "Bearer " + localStorage.accessToken },
    //     })
    //     .then((res) => {
    //       console.log("_______________adminkyc_______________________");
    //       console.log(res.data.data);
    //       this.adminKycs = res.data.data;
    //     });
    // },
    modalHeading() {
      $("#modal-heading-update").html("new");
    },
    createWallet() {
      this.$axios
        .post(
          `superadmin/adminwallet?user_id=${this.$route.params.id}`,
          this.beneficiary_Form,
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          console.log(res.data.data.data);
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Wallet Acount Created  Succesfully",
            showConfirmButton: false,
            timer: 1500,
          });
          this.loadWallet();
        });
    },
    loadWallet() {
      this.$axios
        .get(`superadmin/adminwallet/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log("_______________wallet______________in_________");
          console.log(res.data.data);
          this.wallet = res.data.data;

          this.beneficiary_Form.beneficiary_id = this.wallet.customer_id;
          this.beneficiary_Form.admin_id = this.wallet.admin_id;
        });
    },
    createbeneficiaryAcount() {
      this.$axios
        .post("superadmin/adminbeneficiaryaccount", this.beneficiary_Form, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(
            "____________create ___beneficiaryaccount______________in_________"
          );
          console.log(res.data.data.data);
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Beneficiary Acount Created  Succesfully",
            showConfirmButton: false,
            timer: 1500,
          });
          this.loadBeneficiaryAccount();
        });
    },
    editBeneficiaryAcount(acount, heaing) {
      this.beneficiary_Form.name = acount.account_holder_name;
      this.beneficiary_Form.account_number = acount.account_number;
      this.beneficiary_Form.ifsc = acount.ifsc_code;
      this.beneficiary_Form.bank_name = acount.bank_name;
      this.beneficiary_Form.phone = acount.mobile;
      this.beneficiary_Form.status = acount.status;
      this.beneficiary_Form.remark = acount.remark;
      this.beneficiary_Form_id = acount.id;

      this.beneficiary_id = acount.beneficiary_id;
      this.edit = "true";
      $("#modal-heading-update").html(heaing);
    },
    updatebeneficiaryAcount() {
      this.$axios
        .put(
          `superadmin/adminbeneficiaryaccount/${this.beneficiary_Form_id}`,
          this.beneficiary_Form,
          { headers: { Authorization: "Bearer " + localStorage.accessToken } }
        )
        .then((res) => {
          console.log(
            "____________create ___beneficiaryaccount______________in_________"
          );
          console.log(res.data.data.data);
          this.$swal.fire({
            position: "top-end",
            icon: "success",
            title: "Beneficiary Acount Updated  Succesfully",
            showConfirmButton: false,
            timer: 1500,
          });
          this.beneficiary_Form.name = "";
          this.beneficiary_Form.account_number = null;
          this.beneficiary_Form.ifsc = null;
          this.beneficiary_Form.bank_name = "";
          this.beneficiary_Form.phone = null;
          this.beneficiary_Form.status = "";
          this.beneficiary_Form_id = null;
          this.beneficiary_id = null;
          this.edit = "false";
          this.loadBeneficiaryAccount();
        });
    },
    loadBeneficiaryAccount() {
      this.$axios
        .get(`superadmin/adminbeneficiaryaccount?admin_id=${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log("_______________beneficiaryaccount______________in_________");
          console.log(res.data.data.data);
          this.beneficiaryaccount = res.data.data.data;
        });
    },
    // loadStates() {
    //   this.$axios
    //     .get(`superadmin/state/${this.$route.params.id}`, {
    //       headers: { Authorization: "Bearer " + localStorage.accessToken },
    //     })
    //     .then((res) => {
    //       this.state = res.data.data;
    //     });
    // },

    openWindow(url) {
      window.open(`${url}`);
    },
    loadAdminInfo() {
      this.$axios
        .get(`staff/admin/${this.$route.params.id}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          this.admin = res.data.data;

          if (this.admin.photo != null) {
            this.profileImg =
              this.$store.state.imgUrl +
              "/admin/" +
              this.admin.id +
              "/thumbs/" +
              this.admin.photo;
          } else {
            this.profileImg = this.$store.state.placeholderImg;
          }

         

          if (this.admin.admin_kyc) {
            if (this.admin.admin_kyc.aadharFrontPhoto != null) {
              this.adharFrontImg =
                this.$store.state.imgUrl +
                "/admin/" +
                this.admin.id +
                "/thumbs/" +
                this.admin.admin_kyc.aadharFrontPhoto;
            }
          } else {
            this.adharFrontImg = this.$store.state.placeholderImg;
          }

          if (this.admin.admin_kyc) {
            if (this.admin.admin_kyc.aadharBackPhoto != null) {
              this.adharBackImg =
                this.$store.state.imgUrl +
                "/admin/" +
                this.admin.id +
                "/thumbs/" +
                this.admin.admin_kyc.aadharBackPhoto;
            }
          } else {
            this.adharBackImg = this.$store.state.placeholderImg;
          }

          if (this.admin.admin_kyc) {
            if (this.admin.admin_kyc.panPhoto != null) {
              this.panImg =
                this.$store.state.imgUrl +
                "/admin/" +
                this.admin.id +
                "/thumbs/" +
                this.admin.admin_kyc.panPhoto;
            }
          } else {
            this.panImg = this.$store.state.placeholderImg;
          }






// new kyc

if (this.admin.businessPanPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin.id +
          "/thumbs/" +
          this.admin.businessPanPhoto
        ) {
          this.businessPanPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin.id +
            "/thumbs/" +
            this.admin.businessPanPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin.id +
          "/" +
          this.admin.businessPanPhoto
        ) {
          this.businessPanPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin.id +
            "/" +
            this.admin.businessPanPhoto;
        }
        this.businessPanPreImgPdf = this.businessPanPreImg;
        this.extensionBusinessPan = this.businessPanPreImg.split(".").pop();

        if (this.extensionBusinessPan == "pdf" || this.extensionBusinessPan == "PDF") {
          this.businessPanPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.businessPanPreImg = this.$store.state.placeholderImg;
      }

      if (this.admin.gstinPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin.id +
          "/thumbs/" +
          this.admin.gstinPhoto
        ) {
          this.gstinPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin.id +
            "/thumbs/" +
            this.admin.gstinPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin.id +
          "/" +
          this.admin.gstinPhoto
        ) {
          this.gstinPreImg =
            this.$store.state.imgUrl + "/admin/" + this.admin.id + "/" + this.admin.gstinPhoto;
        }

        this.GstInPreImgPdf = this.gstinPreImg;
        this.extensionGstIn = this.gstinPreImg.split(".").pop();

        if (this.extensionGstIn == "pdf" || this.extensionGstIn == "PDF") {
          this.gstinPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.gstinPreImg = this.$store.state.placeholderImg;
      }

      if (this.admin.cinPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
         this.admin.id +
          "/thumbs/" +
          this.admin.cinPhoto
        ) {
          this.cinPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
           this.admin.id +
            "/thumbs/" +
            this.admin.cinPhoto;
        }
        if (this.$store.state.imgUrl + "/admin/" +this.admin.id + "/" + this.admin.cinPhoto) {
          this.cinPreImg =
            this.$store.state.imgUrl + "/admin/" +this.admin.id + "/" + this.admin.cinPhoto;
        }

        this.cinPreImgPdf = this.cinPreImg;
        this.extensionCin = this.cinPreImg.split(".").pop();

        if (this.extensionCin == "pdf" || this.extensionCin == "PDF") {
          this.cinPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.cinPreImg = this.$store.state.placeholderImg;
      }

      if (this.admin.coiPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin.id +
          "/thumbs/" +
          this.admin.coiPhoto
        ) {
          this.coiPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin.id +
            "/thumbs/" +
            this.admin.coiPhoto;
        }
        if (this.$store.state.imgUrl + "/admin/" +this.admin.id + "/" + this.admin.coiPhoto) {
          this.coiPreImg =
            this.$store.state.imgUrl + "/admin/" +this.admin.id + "/" + this.admin.coiPhoto;
        }

        this.coiPreImgPdf = this.coiPreImg;
        this.extensionCoi = this.coiPreImg.split(".").pop();

        if (this.extensionCoi == "pdf" || this.extensionCoi == "PDF") {
          this.coiPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.coiPreImg = this.$store.state.placeholderImg;
      }

      if (this.admin.tdsPhoto != null) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
         this.admin.id +
          "/thumbs/" +
          this.admin.tdsPhoto
        ) {
          this.tdsPreImg =
            this.$store.state.imgUrl +
            "/admin/" +
           this.admin.id +
            "/thumbs/" +
            this.admin.tdsPhoto;
        }
        if (this.$store.state.imgUrl + "/admin/" +this.admin.id + "/" + this.admin.tdsPhoto) {
          this.tdsPreImg =
            this.$store.state.imgUrl + "/admin/" +this.admin.id + "/" + this.admin.tdsPhoto;
        }

        this.tdsPreImgPdf = this.tdsPreImg;
        this.extensionTds = this.tdsPreImg.split(".").pop();

        if (this.extensionTds == "pdf" || this.extensionTds == "PDF") {
          this.tdsPreImg = "/assets/image/pdf.png";
        }
      } else {
        this.tdsPreImg = this.$store.state.placeholderImg;
      }

      if (this.admin.admin_kyc) {
        // this.panImg = this.$store.state.imgUrl + '/admin/' + this.admin.id + '/thumbs/' + this.admin.admin_kyc.panPhoto
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin.id +
          "/thumbs/" +
          this.admin.admin_kyc.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin.id +
            "/thumbs/" +
            this.admin.admin_kyc.panPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin.id +
          "/" +
          this.admin.admin_kyc.panPhoto
        ) {
          this.panImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin.id +
            "/" +
            this.admin.admin_kyc.panPhoto;
        }

        this.panImgPdf = this.panImg;
        this.extensionkycPanPhoto = this.panImg.split(".").pop();

        if (this.extensionkycPanPhoto == "pdf" || this.extensionkycPanPhoto == "PDF") {
          this.panImg = "/assets/image/pdf.png";
        }
      } else {
        this.panImg = this.$store.state.placeholderImg;
      }

      if (this.admin.admin_kyc) {
        // this.adharFrontImg = this.$store.state.imgUrl + '/admin/' + this.admin.id + '/thumbs/' + this.admin.admin_kyc.aadharFrontPhoto
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin.id +
          "/thumbs/" +
          this.admin.admin_kyc.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin.id +
            "/thumbs/" +
            this.admin.admin_kyc.aadharFrontPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin.id +
          "/" +
          this.admin.admin_kyc.aadharFrontPhoto
        ) {
          this.adharFrontImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin.id +
            "/" +
            this.admin.admin_kyc.aadharFrontPhoto;
        }

        this.adharFrontImgPdf = this.adharFrontImg;
        this.extensionkycAdharFront = this.adharFrontImg.split(".").pop();

        if (this.extensionkycAdharFront == "pdf" || this.extensionkycAdharFront == "PDF") {
          this.adharFrontImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharFrontImg = this.$store.state.placeholderImg;
      }

      if (this.admin.admin_kyc) {
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin.id +
          "/thumbs/" +
          this.admin.admin_kyc.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin.id +
            "/thumbs/" +
            this.admin.admin_kyc.aadharBackPhoto;
        }
        if (
          this.$store.state.imgUrl +
          "/admin/" +
          this.admin.id +
          "/" +
          this.admin.admin_kyc.aadharBackPhoto
        ) {
          this.adharBackImg =
            this.$store.state.imgUrl +
            "/admin/" +
            this.admin.id +
            "/" +
            this.admin.admin_kyc.aadharBackPhoto;
        }

        this.adharBackImgPdf = this.adharBackImg;
        this.extensionkycAdharBack = this.adharBackImg.split(".").pop();

        if (this.extensionkycAdharBack == "pdf" || this.extensionkycAdharBack == "PDF") {
          this.adharBackImg = "/assets/image/pdf.png";
        }
      } else {
        this.adharBackImg = this.$store.state.placeholderImg;
      }





        });
    },
    loadPageTitle(){
        document.title =this.$store.state.staffPageTitles.adminView
      }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadAdminInfo();
      // this.loadStates();
      // this.loadBeneficiaryAccount();
      // this.loadWallet();
      // this.adminKycsLoading();
      this.loadPageTitle()
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
</style>
